.home__slider {

  .carousel__content{
    h1, h2{
      color: $secondary;
    }
  }
}

.mag__item{
  background-color: rgba($secondary, .3);
}

.brochure-item a{
  border-color: $secondary;
  &:hover{
    background-color: $secondary;
  }
}

.news {
  article:not(:first-child) {
    h3{
      border-color: rgba(#fff,.2);
    }
  }
}

.header__site .top__bar{
  background-color: #f3f3f3 !important;
}